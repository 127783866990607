html,
body {
  margin: 0;
  height: 100%;
  background: #073b65; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#073b65, #408bbe); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#073b65, #408bbe); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#073b65, #408bbe); /* For Firefox 3.6 to 15 */
  background: linear-gradient(#073b65, #408bbe); /* Standard syntax (must be last) */
  background-attachment: fixed;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0,0,0,.5);
}
body.savvy {
  color: #fff;
}
main {
  display:block;
}
.fullscreen-container {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(10%, 10%, 10%, 75%);
  z-index: 9999;
}
.condensed {
  font-family: 'Open Sans Condensed', sans-serif;
}
.padding-left-right-7 {
  padding-left: 0.7em;
  padding-right: 0.7em;
}
a {
  text-decoration: none;
  color: #fff;
}
a:focus,
a:hover {
  text-decoration: none;
  color: #f00;
}
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritence from `body` */
  background-color: #fff;
  border: 1px solid #fff;
}
.btn-submit,
.btn-submit:hover,
.btn-submit:focus {
  width: 100%;
  color: #fff;
  text-decoration: none;
  text-shadow: -1px -1px 0 rgba(0,0,0,0.3);
  font-weight: bold;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  display: inline-block;
}
.btn-submit {
  border-color: #3f9038;
  background-color: #51B848; background-image: -webkit-gradient(linear, left top, left bottom, from(#51B848), to(#1A9841));
  background-image: -webkit-linear-gradient(top, #51B848, #1A9841);
  background-image: -moz-linear-gradient(top, #51B848, #1A9841);
  background-image: -ms-linear-gradient(top, #51B848, #1A9841);
  background-image: -o-linear-gradient(top, #51B848, #1A9841);
  background-image: linear-gradient(to bottom, #51B848, #1A9841);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#51B848, endColorstr=#1A9841);
}
.btn-submit:focus,
.btn-submit:hover{
  border-color:#316f2b;
  background-color: #1A9941; background-image: -webkit-gradient(linear, left top, left bottom, from(#1A9941), to(#0E8550));
  background-image: -webkit-linear-gradient(top, #1A9941, #0E8550);
  background-image: -moz-linear-gradient(top, #1A9941, #0E8550);
  background-image: -ms-linear-gradient(top, #1A9941, #0E8550);
  background-image: -o-linear-gradient(top, #1A9941, #0E8550);
  background-image: linear-gradient(to bottom, #1A9941, #0E8550);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#1A9941, endColorstr=#0E8550);
}
.centered-spinner {
  position: absolute;
  top: 20%;
  left: 50%;
  width: 35px;
  height: 35px;
  margin-top: -18px;
  margin-left: -18px;
}
.popup {
  margin: auto;
  margin-top: -75px;
  top: 47%;
  width: 300px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(153,153,153,.95);
  box-shadow: 6px 6px 10px rgba(0,0,0,.5);
  position: relative;
}
a.login {
  color: #ddd;
}
a.login:hover,
a.login:focus {
  text-decoration: none;
  color: #f00;
}
.red {
  color: #f00;
}
.top-right {
  position: absolute;
  top: 0px;
  right: 5px;
}
.close-panel {
  max-width: 20px;
  height: auto;
}
.form-group {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.form-control {
  margin-top: 10px;
}
.brand {
  max-width: 50%;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  padding: 0;
  padding-top: 12px;
}
.inner {
  padding: 30px;
  margin-top: auto;
  margin-bottom: auto;
}
.masthead {
  top: 0;
  width: 100%;
}
.cover {
  padding: 0 20px;
}
.mastfoot {
  color: #999; /* IE8 proofing */
  color: rgba(255,255,255,.5);
  position: fixed;
  left: 50%;
  bottom: 8px;
  transform: translate(-50%, 0);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right {
  position: absolute;
  right: 0px;
  margin-right: 25px;
  padding:  0px;
  font-size: 14px;
}
.left-justify {
  float: left;
  display: inline-block;
}
.center-justify {
  text-align: center;
  display: inline-block;
}
.right-justify {
  float: right;
  display: inline-block;
}
.empty {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid transparent;
  padding-left: 5px;
  padding-right: 5px;
}
.arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid gray;
  margin-left: 5px;
  margin-bottom: 1px;
}
.arrow-right-redzone {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid red;
  margin-left: 5px;
  margin-bottom: 1px;
}
.arrow-left {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 18px solid gray;
  margin-right: 5px;
  margin-bottom: 1px;
}
.arrow-left-redzone {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 18px solid red;
  margin-right: 5px;
  margin-bottom: 1px;
}
.week {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;
  font-size: 50px;
  text-align: center;
}
.games-results {
  padding-bottom: 40px;
}
.games {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, 400px);
  column-gap: 80px;
  row-gap: 20px;
  padding-left: 80px;
  padding-right: 80px;
}
.game {
  width: 400px;
}
.record {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  font-size: 30px;
  text-align: center;
}
.points {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;
  font-size: 30px;
  text-align: center;
}
.venue {
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  font-size: 27px;
  line-height: 0%;
}
.score {
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  font-size: 32px;
  line-height: 0%;
}
.time {
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
}
.watch {
  padding-top: 12px;
  text-align: center;
  font-size: 10px;
}
.team {
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 72px;
  height: 72px;
  filter: drop-shadow(0.2em 0.2em 0.2em #111)
}
.vstack {
  display: block;
  margin-top: 6px;
  margin-bottom: 20px;
}
.team-record {
  font-size: 12px;
}
.win {
  color: #0f6;
}
.loss {
  color: #f03;
}
.tie {
  color: #08f;
}
.slider {
  margin-top: 2px;
  width: 240px;
  height: 48px;
}
.active {
  cursor: ew-resize;
}
.forecast {
  font-family: 'Droid Sans Mono', monospace;
  font-size: 18px;
  letter-spacing:-1px;
  text-align: right-justify;
  stroke: none;
  fill: #fff;
}
.percent {
  font-family: 'Droid Sans Mono', monospace;
  font-size: 9px;
  letter-spacing:-1px;
  stroke: none;
  fill: #fff;
}
.input-rounded-button {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid;
  padding: 10px 13px 10px 13px;
  display: inline-block;
  text-decoration: none;
  background: #3344ff;

  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
}
.input-rounded-button:hover {
  text-decoration: none;
  background: #0000ff;
  cursor: pointer;
  border: 1px solid;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
table.leader_board thead th {
  border-bottom: 1px solid #ccc;
}
table.leader_board {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}
.align_left {
  text-align: left;
}
.align_right {
  text-align: right;
}
.divider {
  border-bottom: 1px solid #aaa;
}
th.leader_board {
  padding: 2px;
  padding: 2px;
  font-size: 16px;
}
td.leader_board {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 22px;
}
